<template>
  <div class="error">
    <div class="error-container">
      <div class="error-item">
        <div class="error-image">
          <van-image src="image/error.png"></van-image>
        </div>
        <div class="error-tip">
          {{ msg }}
        </div>
      </div>
      <a
        v-if="from == 'index'"
        class="consume-button"
        :href="href"
        target="_blank"
        rel="noopener noreferrer"
      >
        进入消费预定
      </a>
      <div v-if="from == 'req'" class="consume-button" @click="goIndex">
        去预约
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      msg: "",
      href: "",
      from: this.$route.query.from,
    };
  },
  mounted() {
    this.msg = this.$route.query.message;
    this.getInit();
  },
  methods: {
    getInit() {
      let that = this;
      that.$axios.get("https://huanghe.xprings.com/api/apply/init").then((res) => {
        that.href = res.data.data.consume_url;
      });
    },
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
.error-container {
  width: 345px;
  height: 459px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -172.5px;
  margin-top: -230px;
}
.error-image {
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
}
.error-tip {
  color: #333;
  font-size: 16px;
  margin-bottom: 28px;
  font-weight: 600;
}
.error-item {
  width: 345px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 14px 7px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.consume-button {
  width: 345px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  color: #f7f8fa;
  background: linear-gradient(90deg, #ffae00, #ff9000);
  box-shadow: 0px 7px 11px 2px rgba(255, 168, 0, 0.34);
  border-radius: 43px;
  margin-top: 44px;
  display: block;
}
</style>
